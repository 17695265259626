@font-face {
    font-family: 'NouvelR';
    src: url('NouvelR-Regular.otf') format('opentype');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'NouvelRBold';
    src: url('NouvelR-Bold.otf') format('opentype');
    font-weight: normal;
    font-style: normal;
}